export default {
  primary: "#376E94",
  primaryHover: "#316385",
  primarySelected: "rgba(157, 189, 214, 0.7)",
  primaryMuted: "#9DBDD6",
  accent1: "#f3e7d1",
  accent1Hover: "#eadec8",
  accent2: "#d26a50",
  background: "#fff",
  greyLight: "#fafafa",
  grey: "#e0e0e0",
  textDark: "#000",
  textMuted: "#888",
  textLight: "#fff",
  tableDivider: "#ececec",
  success: "#3f8e4f",
  error: "#d54a26",
  malePanel: "#cbdeef",
  maleSymbol: "#2986cc",
  femalePanel: "#f1dce6",
  femaleSymbol: "#c90076",
  analytics1: "#4394E5",
  analytics2: "#F5921B",
  analytics3: "#87BB62",
  analytics4: "#876FD4",
}
