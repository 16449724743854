export const Tooltip = {
  baseStyle: {
    color: "textDark",
    bgColor: "background",
    borderRadius: 12,
    px: 4,
    py: 2,
  },
  sizes: {},
  variants: {
    bookingInfo: {
      bgColor: "background",
    },
  },
  defaultProps: {},
}
