import {
  FirestoreBooking,
  FirestoreBookingAppointmentSegment,
  FirestoreDog,
  FirestorePawrent,
  FirestorePawrentInternalFields,
  FirestoreRecentUpdate,
  FirestoreTeamMember,
  FirestoreUser,
  FirestoreUserInternalSettings,
} from "../types/firebaseTypes"
import { DocumentSnapshot, Timestamp } from "firebase/firestore"
import { QueryDocumentSnapshot } from "firebase-admin/lib/firestore"
import { DocumentSnapshot as AdminDocumentSnapshot } from "firebase-admin/firestore"

const timestampToDate = (timestamp?: Timestamp) =>
  timestamp ? new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6) : new Date()

export const docToFirestoreUser = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestoreUser => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    role: data?.role || "",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestoreUserInternalSettings = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestoreUserInternalSettings => {
  const data = doc.data()
  return {
    calendarShowInactiveBookings: data?.calendarShowInactiveBookings || false,
    calendarViewRange: data?.calendarViewRange || "",
  }
}

export const docToFirestorePawrent = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestorePawrent => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    phone: data?.phone || "",
    address: data?.address || "",
    emergencyName: data?.emergencyName || "",
    emergencyPhone: data?.emergencyPhone || "",
    ownerInstagram: data?.ownerInstagram || "",
    dogInstagram: data?.dogInstagram || "",
    mergedId: data?.mergedId || null,
    deletedAt: timestampToDate(data?.deletedAt),
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestorePawrentInternalFields = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestorePawrentInternalFields => {
  const data = doc.data()
  return {
    internalNotes: data?.internalNotes || "",
    termsPhysicalSigned: data?.termsPhysicalSigned || false,
    termsDigitalSigned: data?.termsDigitalSigned || false,
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestoreDog = (
  doc: QueryDocumentSnapshot | DocumentSnapshot | AdminDocumentSnapshot
): FirestoreDog => {
  const data = doc.data()
  return {
    id: doc.id,
    name: data?.name || "",
    breed: data?.breed || "",
    color: data?.color || "",
    gender: data?.gender || "unknown",
    desexed: data?.desexed || "unknown",
    age: data?.age || -1,
    weight: data?.weight || -1,
    medicalNotes: data?.medicalNotes || "",
    dietaryNotes: data?.dietaryNotes || "",
    behavioralNotes: data?.behavioralNotes || "",
    groomingNotes: data?.groomingNotes || "",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestoreBooking = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestoreBooking => {
  const data = doc.data()
  return {
    id: doc.id,
    customerId: data?.customerId || "",
    locationId: data?.locationId || "",
    status: data?.status || "DECLINED",
    appointmentSegments:
      data?.appointmentSegments?.map((segment: FirestoreBookingAppointmentSegment) => ({
        serviceVariationId: segment?.serviceVariationId || "",
        teamMemberId: segment?.teamMemberId || "",
        anyTeamMember: segment?.anyTeamMember || false,
        durationMinutes: segment?.durationMinutes || 0,
      })) || [],
    customerNote: data?.customerNote || "",
    sellerNote: data?.sellerNote || "",
    startAt: timestampToDate(data?.startAt),
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestoreTeamMember = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestoreTeamMember => {
  const data = doc.data()
  return {
    id: doc.id,
    email: data?.email || "",
    phone: data?.phone || "",
    firstName: data?.firstName || "",
    lastName: data?.lastName || "",
    status: data?.status || "INACTIVE",
    createdAt: timestampToDate(data?.createdAt),
    updatedAt: timestampToDate(data?.updatedAt),
  }
}

export const docToFirestoreRecentUpdate = (
  doc: DocumentSnapshot | QueryDocumentSnapshot | AdminDocumentSnapshot
): FirestoreRecentUpdate => {
  const data = doc.data()
  return {
    id: doc.id,
    documentType: data?.documentType || "unknown",
    pawrentId: data?.pawrentId || "",
    dogId: data?.dogId || "",
    adminId: data?.adminId || "",
    title: data?.title || "",
    changeType: data?.changeType || "unknown",
    updatedAt: timestampToDate(data?.updatedAt),
  }
}
